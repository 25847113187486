import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";

import { useAuth0 } from "@auth0/auth0-react";

import { Document, buildDocument, TenantContext } from "./state";
import { KendoGrid } from "./kendo-grid";

import useFetch, { FetchResponse } from "./useFetch";

interface DocumentGridRow extends Document {
    categoriesDisplayValue: string;
    groupsDisplayValue: string;
}

export const DocumentsPage = () => {
    // State
    const [documents, setDocuments] = useState<DocumentGridRow[]>([]);
    const tenantState = useContext(TenantContext);

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const documentsApi = useFetch("/api/documents");

    // Effects
    useEffect(() => {
        if (isAuthenticated && tenantState.currentTenant) {
            documentsApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const documents = buildDocuments(response.result.documents);
                        setDocuments(documents);
                    }
                });
        }
    }, [isAuthenticated, tenantState]);

    // Methods
    const buildDocuments = (documentResponse: any[]): DocumentGridRow[] => {
        return documentResponse.map<DocumentGridRow>(documentResponse => {
            var document = buildDocument(documentResponse);
            return {
                ...document,
                categoriesDisplayValue: document.categories ? document.categories?.map((c: any) => c.name)?.join(", ") : "",
                groupsDisplayValue: document.groups ? (document.groups.length === 0 ? "All" : document.groups.map((c: any) => c.name).join(", ")) : ""
            }
        });
    };

    const editDocument = (documentId: string): void => {
        history.push(`/document/${documentId}`);
    }

    const sort: SortDescriptor[] = [
        { field: 'date', dir: 'desc' }
    ];

    const filter: CompositeFilterDescriptor = {
        filters: [
            {
                filters: [
                    { field: "status", operator: "neq", value: "Archived" }
                ],
                logic: "and"
            }
        ],
        logic: "and"
    };

    return (
        <section id="documents-page">
            <div className="title">
                <h1>Documents</h1>
                <div className="controls">
                    <Button onClick={(e) => { history.push("/document/create") }} primary={true}>Create</Button>
                </div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} filter={filter} groupable={true} data={documents} onEdit={editDocument}>
                    <GridColumn title="Date" field="date" format="{0:MM/dd/yy}" width={10} />
                    <GridColumn title="Title" field="title" width={30} />
                    <GridColumn title="Categories" field="categoriesDisplayValue" groupable={false} width={30} />
                    <GridColumn title="Status" field="status" width={10} />
                    <GridColumn title="Groups" field="groupsDisplayValue" groupable={false} width={30} />
                    <GridColumn title="Created By" field="createdByUserName" width={15} />
                    <GridColumn title="View Count" field="viewCount" className="column-right" groupable={false} width={10} />
                </KendoGrid>
            </div>
        </section>
    );
}