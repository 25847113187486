import React, { useEffect, useState, useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import '@progress/kendo-theme-default/dist/all.css';

import { useAuth0 } from "@auth0/auth0-react";

import './custom.css'

import { TenantSelect } from "./tenant-select";
import { Nav } from "./nav";

import { DocumentsPage } from "./documents-page";
import { UsersPage } from "./users-page";
import { UsersPendingPage } from "./users-pending-page";
import { UsersRejectedPage } from "./users-rejected-page";
import { SendEmailNotificationPage } from "./send-email-notification-page";
import { GroupsPage } from "./manage/groups-page";
import { CategoriesPage } from "./manage/categories-page";
import { TagsPage } from "./manage/tags-page";
import { EditConfigurationPage } from "./manage/edit-configuration-page";
import { EditContentPage } from "./manage/edit-content-page";
//import { HelpPage } from "./help-page";
import { BlankPage } from "./blank-page";
import { EditDocumentPage } from "./edit-document-page";
import { EditUserPage } from "./edit-user-page";
import { EditEmailPage } from "./edit-email-page";
import { ActivitiesPage } from "./activities-page";

import { LoadingIndicator } from "./loading-indicator";

import useFetch, { FetchResponse } from "./useFetch";

import { DispatchContext, TenantContext, navigation, ActionType, NavigationItem, AppStateContext } from "./state";
import { Button } from "@progress/kendo-react-buttons";

export const RoutedApp = () => {
    // State
    const { dispatch } = useContext(DispatchContext);
    const { triggerAppStateUpdate } = useContext(AppStateContext);
    const tenantState = useContext(TenantContext);

    const [logoDataUri, setLogoDataUri] = useState("");

    const history = useHistory();

    // Auth0
    const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    // API
    const appApi = useFetch("/api/app");
    const logoApi = useFetch("/theme/logo");

    const getAppState = (): void => {
        appApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    dispatch({
                        type: ActionType.SetAppState,
                        categories: response.result.categories,
                        tags: response.result.tags,
                        groups: response.result.groups
                    })
                }
            });
    }

    //Effects
    useEffect(() => {
        if (!user) {
            history.push("/");
        }
    }, [user]);

    useEffect(() => {
        if (!isAuthenticated) return;
        
        if (tenantState.currentTenant) {
            getAppState();

            logoApi
                .get()
                .then((response: FetchResponse) => {
                    setLogoDataUri(`data:image/png;base64,${response.result.logo}`);
                });
        }

    }, [tenantState, isAuthenticated]);

    useEffect(() => {
        if (triggerAppStateUpdate) {
            getAppState();
        }
    }, [triggerAppStateUpdate]);

    useEffect(() => {
        if (isLoading) {
            dispatch({ type: ActionType.StartLoading });
        }
        else {
            dispatch({ type: ActionType.EndLoading });
        }
    }, [isLoading]);

    // Navigation
    const getPath = (name: string) => {
        let navItem: NavigationItem = navigation.filter((n: NavigationItem) => { return n.name === name; })[0];

        if (!navItem) {
            navigation.forEach((n) => {
                if (n.children) {
                    n.children.forEach((c: NavigationItem) => {
                        if (c.name === name) {
                            navItem = c;
                        }
                    });
                }
            });
        }

        return navItem.path;
    }

    return (<>
        <header id="site-header">
            <div id="tenant-select">
                {isAuthenticated && tenantState.tenants.length > 1 && <TenantSelect />}
            </div>

            <div className="navigation">
                <div id="logo">
                    {tenantState.currentTenant && logoDataUri &&
                        <img src={logoDataUri} alt="logo" />
                    }
                </div>
                <Nav />
            </div>
        </header>
        <main>
            <LoadingIndicator />
            {user && tenantState.currentTenant &&
                <Switch>
                    <Route exact path={getPath("documents")} component={DocumentsPage} />
                    <Route exact path={getPath("users")} component={UsersPage} />
                    <Route exact path={getPath("pending")} component={UsersPendingPage} />
                    <Route exact path={getPath("notifications")} component={SendEmailNotificationPage} />
                    <Route exact path={getPath("templates")} component={EditEmailPage} />
                    <Route exact path={getPath("groups")} component={GroupsPage} />
                    <Route exact path={getPath("categories")} component={CategoriesPage} />
                    <Route exact path={getPath("tags")} component={TagsPage} />
                    <Route exact path={getPath("configuration")} component={EditConfigurationPage} />
                    <Route exact path={getPath("content")} component={EditContentPage} />
                    {/*<Route exact path={getPath("help")} component={HelpPage} />*/}
                    <Route exact path={getPath("document")} component={EditDocumentPage} />
                    <Route exact path={getPath("user")} component={EditUserPage} />
                    <Route exact path="/users/rejected" component={UsersRejectedPage} />
                    <Route exact path="/activity" component={ActivitiesPage} />
                    <Route component={() => <BlankPage />} />
                </Switch>
            }

            {!user &&
                <div style={{ margin: "-1rem auto 0", textAlign: "center" }}>
                    <Button onClick={() => loginWithRedirect()} primary={true} style={{ padding: ".5rem 1.5rem", fontSize: "1.4rem" }}>Log In</Button>
                </div>}
        </main>
    </>);
}