import React, { useState, FormEvent, useEffect } from "react";
import { useHistory } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import useFetch, { FetchResponse } from "../useFetch";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import { RequiredLabel } from "../required-label";
import useNotification from "../useNotification";

interface SiteConfiguration {
    id: string;
    accountRequestUserIds: string[];
    description: string;
    emailFromName: string;
    enableIndexing: boolean;
    googleAnalyticsId: string;
    tagsEnabled: boolean;
    title: string;
    supportEmail: string;
}

export const EditConfigurationPage = (props: any) => {
    // State
    const initalConfigurationState: SiteConfiguration = {
        id: "siteConfiguration",
        accountRequestUserIds: [],
        description: "",
        emailFromName: "",
        enableIndexing: false,
        googleAnalyticsId: "",
        tagsEnabled: false,
        title: "",
        supportEmail: ""
    }

    const [config, setConfig] = useState<SiteConfiguration>(initalConfigurationState);

    const [users, setUsers] = useState<{id: string, emailAddress: string, name: string }[]>([]);

    const [selectedUsers, setSelectedUsers] = useState<{ id: string, name: string }[]>([]);

    const [emailInvalid, setEmailInvalid] = useState(false);

    const history = useHistory();

    const [emailDomain, setEmailDomain] = useState("");

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const configApi = useFetch("/api/config");
    const usersApi = useFetch("/api/users");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            configApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const config: any = response.result;
                        setConfig(
                            {
                                id: initalConfigurationState.id,
                                accountRequestUserIds: config.accountRequestUserIds,
                                description: config.description || "",
                                emailFromName: config.emailFromName || "",
                                enableIndexing: config.enableIndexing,
                                googleAnalyticsId: config.googleAnalyticsId || "",
                                tagsEnabled: config.tagsEnabled,
                                title: config.title,
                                supportEmail: config.supportEmail
                            }
                        );

                        setEmailDomain(config.emailDomain);
                    }
                });

            usersApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const usersResponse: any[] = response.result.users;
                        const users: { id: string, emailAddress: string, name: string }[] = usersResponse
                                    .filter(u => u.isAdmin)
                                    .map<{ id: string, emailAddress: string, name: string }>((userResponse: any) => {
                                        return {
                                            id: userResponse.id,
                                            name: `${userResponse.firstName} ${userResponse.lastName}`,
                                            emailAddress: userResponse.emailAddress
                                        }
                                    });
                        setUsers(users);
                    }
                });
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (config.accountRequestUserIds.length > 0 && users.length > 0) {
            setSelectedUsers(users.filter(u => config.accountRequestUserIds.indexOf(u.id) >= 0));
        }

    }, [config.accountRequestUserIds, users])

    // Methods
    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        configApi
            .put(config.id, config)
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success("Site configuration successfully updated");
                }
            });
    }

    const submitDisabled = (): boolean => {
        return !config.title || !config.emailFromName || emailInvalid;
    }

    const updateRequestIds = (values: any): void => {
        setConfig({ ...config, accountRequestUserIds: values.map((v: any) => v.id) });
        setSelectedUsers(values);
    }

    return (
        <section id="configuration-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Site Configuration</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/") }}>Cancel</Button>
                        <Button disabled={submitDisabled()} primary={true}>Save</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>Site Title</h2>
                        <div>
                            <RequiredLabel htmlFor="siteTitle" field={config.title}>Site Title</RequiredLabel>
                            <Input
                                id="siteTitle"
                                name="siteTitle"
                                required
                                onChange={(e) => setConfig({ ...config, title: e.target.value?.toString() || "" })}
                                value={config.title} />
                        </div>
                        {/*<div>
                            <label htmlFor="description">Description (Optional)</label>
                            <TextArea
                                id="description"
                                name="description"
                                value={config.description}
                                onChange={(e) => setConfig({ ...config, description: e.value?.toString() || "" })}
                                autoSize={true}
                                rows={4}
                            />
                        </div>*/}

                        {/*<h2>SEO</h2>
                        <div>
                            <label htmlFor="allow-indexing">Allow Indexing By Search Engines</label>
                            <RadioButton
                                label="Yes"
                                id="allow-indexing"
                                name="indexing"
                                value="yes"
                                checked={config.enableIndexing}
                                onChange={(e) => { setConfig({ ...config, enableIndexing: true }) } } />
                            <RadioButton
                                label="No"
                                name="indexing"
                                value="no"
                                checked={!config.enableIndexing}
                                onChange={(e) => { setConfig({ ...config, enableIndexing: false }) }} />
                        </div>

                        <h2>Analytics</h2>
                        <div>
                            <label htmlFor="google-id">Google Analytics Id (Optional)</label>
                            <Input
                                id="google-id"
                                name="google-id"
                                onChange={(e) => setConfig({ ...config, googleAnalyticsId: e.value?.toString() || "" })}
                                value={config.googleAnalyticsId} />
                        </div>*/}

                        <h2>Email Sender</h2>
                        <div>
                            <RequiredLabel htmlFor="from-name" field={config.emailFromName}>From Name</RequiredLabel>
                            <Input
                                id="from-name"
                                name="from-name"
                                required
                                onChange={(e) => {
                                    setEmailInvalid(e.target.validity.typeMismatch || false);
                                    setConfig({ ...config, emailFromName: e.value?.toString() || "" });
                                }}
                                value={config.emailFromName} />
                        </div>

                        <h2>Account Request Notifications</h2>
                        <div>
                            <label htmlFor="request-email">Select Admins To Receive Notices When Someone Requests Account</label>
                            <MultiSelect
                                id="request-email"
                                name="request-email"
                                data={users}
                                onChange={(e) => { updateRequestIds(e.value); }}
                                value={selectedUsers}
                                textField="name"
                                dataItemKey="id"
                            />
                        </div>

                        <h2>Support Email</h2>
                        <div>
                            <label htmlFor="support-email">Email Address</label>
                            <Input
                                id="support-email"
                                name="support-email"
                                onChange={(e) => {
                                    setConfig({ ...config, supportEmail: e.value?.toString() || "" });
                                }}
                                value={config.supportEmail} />
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}