import React, { useState, useEffect } from "react";

export const RequiredLabel = (props: { htmlFor: string, field: any, children: any }) => {
    const [storedValue, setStoredValue] = useState("");
    const [valueSet, setValueSet] = useState(false);

    useEffect(() => {
        if (props.field && !valueSet) {
            setStoredValue(props.field);
            setValueSet(true);
        }

    }, [props.field])

    return (
        <label htmlFor={props.htmlFor}>{props.children} {!props.field && storedValue !== props.field && <span className="required">(required)</span>}</label>
    );
}