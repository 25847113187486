import React, { useEffect, useState, useContext } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { SortDescriptor } from "@progress/kendo-data-query";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Dialog } from '@progress/kendo-react-dialogs';

import { KendoGrid } from "./kendo-grid";

import { User, UserStatus, Group, AppStateContext } from "./state";

import useFetch, { FetchResponse } from "./useFetch";
import useNotification from "./useNotification";

interface UserRejectedGridRow extends User {
    emailVerifiedDisplayValue: string;
}

export const UsersRejectedPage = () => {
    // State
    const { groups } = useContext(AppStateContext);

    const initialEditingUserState: User = {
        id: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        emailVerified: false,
        organization: "",
        isAdmin: false,
        status: UserStatus.Pending,
        createdDate: new Date(),
        lastLogin: new Date(),
        loginCount: 0,
        groups: [],
        groupIds: []
    }

    const emailVerifiedDropDownOptions = [{ text: "Verified", value: true }, { text: "Unverified", value: false }];

    const [users, setUsers] = useState<UserRejectedGridRow[]>([]);
    const [editingUser, setEditingUser] = useState(initialEditingUserState);
    const [showEditUser, setShowEditUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const usersApi = useFetch("/api/users");
    const usersRejectedApi = useFetch("/api/users/rejected");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            getData();
        }
    }, [isAuthenticated])

    // Grid
    const editUser = (userId: string): void => {
        setEditingUser(users.filter(u => u.id === userId)[0]);
        setShowEditUser(true);
    }

    const sort: SortDescriptor[] = [
        { field: 'lastName', dir: 'asc' }
    ];

    // Methods
    // ToDo Review unreject logic
    const unrejectUser = (): void => {
        const user = { ...editingUser, status: UserStatus.Pending };

        setLoading(true);
        usersApi.put(user.id, user)
            .then((response: FetchResponse) => {
                if (response.success) {
                    getData();
                    notifier.success("User's status has been set to 'Pending'");
                    closeEditor();
                    setLoading(false);
                }
            });
    }

    const updateGroups = (groups: Group[]): void => {
        const groupIds = groups.map(g => g.id);
        setEditingUser({ ...editingUser, groups: groups, groupIds: groupIds });
    }

    const closeEditor = () => {
        setEditingUser(initialEditingUserState);
        setShowEditUser(false);
        setEmailVerificationSent(false);
    }

    const getData = () => {
        usersRejectedApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    const usersResponse: any[] = response.result.users;
                    const users: UserRejectedGridRow[] = usersResponse.map<UserRejectedGridRow>((userResponse: any) => {
                        return {
                            id: userResponse.id,
                            firstName: userResponse.firstName,
                            lastName: userResponse.lastName,
                            emailAddress: userResponse.emailAddress,
                            emailVerified: userResponse.emailVerified,
                            emailVerifiedDisplayValue: userResponse.emailVerified ? "Verified" : "Unverified",
                            organization: userResponse.organization || "",
                            isAdmin: userResponse.isAdmin,
                            status: userResponse.status,
                            createdDate: new Date(userResponse.createdDate),
                            lastLogin: new Date(userResponse.lastLogin),
                            loginCount: userResponse.loginCount,
                            groups: userResponse.groups,
                            groupIds: userResponse.groupIds
                        }
                    });
                    setUsers(users);
                }
            });
    }

    const sendVerificationEmail = (event: any): void => {
        event.preventDefault();
        setEmailVerificationSent(true);
        usersApi
            .put(`verify/${editingUser.id}`, {})
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success(`A verification email will be sent to ${editingUser.emailAddress}.`);
                }
                else {
                    setEmailVerificationSent(false);
                }
            })
    }

    return (
        <section id="users-page">
            <div className="title">
                <h1>Rejected Users</h1>
                <div className="controls">
                    &nbsp;
                </div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} groupable={true} data={users} onEdit={editUser}>
                    <GridColumn title="First Name" field="firstName" width={15} />
                    <GridColumn title="Last Name" field="lastName" width={15} />
                    <GridColumn title="Email" field="emailAddress" width={20} />
                    <GridColumn title="Email Status" field="emailVerifiedDisplayValue" width={10}
                        cell={(props) => {
                            return (<td><span style={!props.dataItem.emailVerified ? { color: "#f00" } : {}}>{props.dataItem.emailVerifiedDisplayValue}</span></td>)
                        }} />
                    <GridColumn title="Organization" field="organization" width={15} />
                    <GridColumn title="Created" field="createdDate" format="{0:MM/dd/yy}" width={10} />
                </KendoGrid>
            </div>

            {showEditUser &&
                <Dialog title="Edit User" onClose={closeEditor}>
                    {loading &&
                        <div className="k-loading-mask">
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image"></div>
                            <div className="k-loading-color"></div>
                        </div>
                    }
                    <div style={{ textAlign: "center" }}>
                        <Button onClick={(e) => { e.preventDefault(); unrejectUser(); }} primary={true} style={{ marginRight: "2rem" }}>Unreject User</Button>
                    </div>

                    <div className="page">
                    <h2>Contact</h2>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="firstName">First Name</label>
                            <Input
                                id="firstName"
                                name="firstName"
                                onChange={(e) => setEditingUser({ ...editingUser, firstName: e.target.value?.toString() || "" })}
                                value={editingUser.firstName} />
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="lastName">Last Name</label>
                            <Input
                                id="lastName"
                                name="lastName"
                                onChange={(e) => setEditingUser({ ...editingUser, lastName: e.target.value?.toString() || "" })}
                                value={editingUser.lastName} />
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="emailAddress">Email Address</label>
                            <Input
                                id="emailAddress"
                                name="emailAddress"
                                onChange={(e) => setEditingUser({ ...editingUser, emailAddress: e.target.value?.toString() || "" })}
                                value={editingUser.emailAddress} />
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="organization">Organization</label>
                            <Input
                                id="organization"
                                name="organization"
                                onChange={(e) => setEditingUser({ ...editingUser, organization: e.target.value?.toString() || "" })}
                                value={editingUser.organization} />
                        </div>
                        <h2>Settings</h2>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="emailVerification">Email Verified</label>
                            <DropDownList
                                data={emailVerifiedDropDownOptions}
                                value={emailVerifiedDropDownOptions.find(o => o.value === editingUser.emailVerified)}
                                onChange={(e) => { setEditingUser({ ...editingUser, emailVerified: e.target.value.value }) }}
                                textField="text"
                                dataItemKey="value" />
                            {!editingUser.emailVerified &&
                                <Button onClick={(e) => { sendVerificationEmail(e) }} style={{ marginLeft: "1rem" }} disabled={emailVerificationSent}>Resend Verification</Button>
                            }
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <label htmlFor="groups">Group(s)</label>
                            <div>
                                <MultiSelect
                                    id="groups"
                                    name="groups"
                                    data={groups}
                                    onChange={(e) => { updateGroups(e.target.value) }}
                                    value={editingUser.groups}
                                    textField="name"
                                    dataItemKey="id"
                                    style={{ marginTop: ".5rem" }}
                                />
                            </div>
                        </div>
                    </div>

                </Dialog>
            }
        </section>
    );
}