import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";

import { useAuth0 } from "@auth0/auth0-react";

import { KendoGrid } from "./kendo-grid";
import useFetch, { FetchResponse } from "./useFetch";
import { User } from "./state";

interface UserGridRow extends User {
    isAdminDisplayValue: string;
    groupsDisplayValue: string;
}

export const UsersPage = () => {
    // State
    const [users, setUsers] = useState<UserGridRow[]>([]);

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const usersApi = useFetch("/api/users");

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            usersApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const usersResponse: any[] = response.result.users;
                        const users: UserGridRow[] = usersResponse.map<UserGridRow>((userResponse: any) => {
                            var groups = userResponse.groups;
                            return {
                                id: userResponse.id,
                                firstName: userResponse.firstName,
                                lastName: userResponse.lastName,
                                emailAddress: userResponse.emailAddress,
                                emailVerified: userResponse.emailVerified,
                                isEmailVerifiedDisplayValue: userResponse.emailVerified ? "Yes" : "No",
                                organization: userResponse.organization,
                                isAdmin: userResponse.isAdmin,
                                isAdminDisplayValue: userResponse.isAdmin ? "Yes": "No",
                                status: userResponse.status,
                                createdDate: new Date(userResponse.createdDate),
                                lastLogin: new Date(userResponse.lastLogin),
                                loginCount: userResponse.loginCount,
                                groups: groups,
                                groupIds: userResponse.groupIds,
                                groupsDisplayValue: groups ? (groups.length === 0 ? "[None]" : groups.map((c: any) => c.name).join(", ")) : ""
                            }
                        });
                        setUsers(users);
                    }
                });
        }
    }, [isAuthenticated])

    // Grid
    const editUser = (userId: string): void => {
        history.push(`/user/${userId.replace("user-auth0|", "")}`);
    }

    const sort: SortDescriptor[] = [
        { field: 'lastName', dir: 'asc' }
    ];

    const filter: CompositeFilterDescriptor = {
        filters: [
            {
                logic: "and",
                filters: [{ field: "status", operator: "eq", value: "Active" }]
            }
        ],
        logic: "and"
    };

    return (
        <section id="users-page">
            <div className="title">
                <h1>Users</h1>
                <div className="controls">
                    &nbsp;
                </div>
            </div>
            <div className="page">
                <KendoGrid filter={filter} sort={sort} groupable={true} data={users} onEdit={editUser} selectedFields={["firstName", "lastName", "emailAddress", "groupsDisplayValue", "isAdminDisplayValue", "status", "createdDate", "lastLogin", "loginCount"]}>
                    <GridColumn title="First Name" field="firstName" width={15} />
                    <GridColumn title="Last Name" field="lastName" width={15} />
                    <GridColumn title="Email" field="emailAddress" width={20} />
                    <GridColumn title="Groups" field="groupsDisplayValue" groupable={false} width={20} />
                    <GridColumn title="Admin" field="isAdminDisplayValue" width={5} />
                    <GridColumn title="Status" field="status" width={10} />
                    <GridColumn title="Organization" field="organization" width={10} />
                    <GridColumn title="Verified" field="isEmailVerifiedDisplayValue" width={5} />
                    <GridColumn title="Create Date" field="createdDate" format="{0:MM/dd/yy}" width={10} />
                    <GridColumn title="Last Login" field="lastLogin" format="{0:MM/dd/yy}" width={10} />
                    <GridColumn title="Logins" field="loginCount" className="column-right" groupable={false} width={5} />
                </KendoGrid>
            </div>
        </section>
    );
}