import React, { useState } from "react";

import { Editor, EditorTools } from '@progress/kendo-react-editor';import { RequiredLabel } from "./required-label";
;

const { Bold, Italic, Underline, OrderedList, UnorderedList, Link, Unlink, FormatBlock, ViewHtml } = EditorTools;

export const HtmlTextArea = (props: { label: string, value: any, onChange: any, required?: boolean }) => {
    const [editor, setEditor] = useState<Editor | null>(null);
    return (
        <>
            {!props.required &&
                <label onClick={(e) => { e.preventDefault(); editor?.focus() }}>{props.label}</label>
            }
            {props.required &&
                <RequiredLabel htmlFor={props.label.replace(" ", "-").toLowerCase()} field={props.value}>{props.label}</RequiredLabel>
            }
            <Editor
                tools={[
                    [Bold, Italic, Underline],
                    [Link, Unlink],
                    [OrderedList, UnorderedList],
                    FormatBlock,
                    [ViewHtml]
                ]}
                contentStyle={{ height: 200 }}
                ref={setEditor}
                value={props.value}
                onChange={(e: any) => { props.onChange(e.html); }}
            />
        </>
    );
}