import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";

import { TenantContext, DispatchContext, ActionType } from "./state";
import useNotification from "./useNotification";

const useFetch = (endpoint: string, tenantRestriction: boolean = true) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const tenantState = useContext(TenantContext);
    const { dispatch } = useContext(DispatchContext);

    const notifier = useNotification();

    const defaultHeader: any = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8"
    };

    const customFetch = async (url: string, method: string, body: any = null, headers = defaultHeader) => {
        const options = {
            method,
            headers,
            body
        };

        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${token}`
            };
        }

        if (tenantRestriction && !tenantState.currentTenant) {
            console.warn("Tenant is not set")
            return { success: false, result: {}};
        }

        if (tenantState.currentTenant) {
            options.headers = {
                ...options.headers,
                "TenantName": tenantState.currentTenant.name
            };
        }

        if (body) {
            options.body = JSON.stringify(body);
        }

        dispatch({ type: ActionType.StartLoading });

        return fetch(url, options)
            .then(async (response) => {
                dispatch({ type: ActionType.EndLoading });

                let fetchResponse: FetchResponse = {
                    success: response.ok,
                    result: {}
                };

                if (response.ok) {
                    fetchResponse.result = await response.json()
                }

                return fetchResponse;
            })
            .catch(error => {
                dispatch({ type: ActionType.EndLoading });
                console.error(error);
                notifier.error("An error occured");
                throw new Error(error);
            });
    };

    const get = (id: any = null) => {
        var url = `${endpoint}${id ? `/${id}` : ""}`;
        return customFetch(url, "GET");
    };

    const post = (body: any) => {
        if (!body) {
            throw new Error("to make a post you must provide a body");
        }
        return customFetch(endpoint, "POST", body);
    };

    const put = (id: string, body: any) => {
        if (!id || !body) {
            throw new Error("to make a put you must provide the id and the body");
        }
        const url = `${endpoint}/${id}`;
        return customFetch(url, "PUT", body);
    };

    const del = (id: string) => {
        if (!id) {
            throw new Error("to make a delete you must provide the id");
        }
        const url = `${endpoint}/${id}`;
        return customFetch(url, "DELETE");
    };

    return {
        get,
        post,
        put,
        del
    };
};

export default useFetch;

export interface FetchResponse {
    success: boolean;
    result: any;
}