import * as React from "react";
import { Dispatch } from "react";

// NAVIGATION
export interface NavigationItem {
    name: string;
    path: string;
    displayName: string;
    children?: NavigationItem[]
}

export const navigation: NavigationItem[] = [
    { name: "documents", path: "/", displayName: "Documents" },
    {
        name: "users", path: "/users", displayName: "Users", children: [
            { name: "users", path: "/users", displayName: "All Users" },
            { name: "pending", path: "/users/pending", displayName: "Pending Users" }
        ]
    },
    { name: "notifications", path: "/notifications", displayName: "Email" },
    { name: "manage", path: "/configuration", displayName: "Manage", children: [
        { name: "configuration", path: "/configuration", displayName: "Configuration" },
        { name: "categories", path: "/categories", displayName: "Categories" },
        { name: "templates", path: "/email", displayName: "Email Templates" },
        { name: "content", path: "/content", displayName: "Site Content" },
        { name: "tags", path: "/tags", displayName: "Tags" },
        { name: "groups", path: "/groups", displayName: "Groups" }]
    },
    //{ name: "help", path: "/help", displayName: "Help" },
    { name: "document", path: "/document/:id", displayName: "" },
    { name: "user", path: "/user/:id", displayName: "" }
];

// HELPERS
export const buildDocument = (documentResponse: any): Document => {
    const document: Document = {
        id: documentResponse.id,
        title: documentResponse.title,
        description: documentResponse.description,
        fileName: documentResponse.file.name,
        originalFileName: documentResponse.file.originalName,
        fileType: documentResponse.file.type,
        fileStatus: documentResponse.file.status,
        groups: documentResponse.groups,
        groupIds: documentResponse.groupIds,
        categories: documentResponse.categories,
        tags: documentResponse.tags,
        status: documentResponse.status,
        date: new Date(documentResponse.date),
        viewCount: documentResponse.viewCount,
        createdByUserName: documentResponse.createdByUserName,
        createdDate: new Date(documentResponse.createdDate),
        modifiedByUserName: documentResponse.modifiedByUserName,
        modifiedDate: new Date(documentResponse.modifiedDate),
        lastViewUserName: documentResponse.lastViewUserName,
        lastViewDate: new Date(documentResponse.lastViewDate)
    }

    return document;
}

// TYPES
export interface Tenant {
    id: string;
    displayName: string;
    emailFromName: string;
    name: string;
    title: string;
}

export interface TenantState {
    currentTenant?: Tenant;
    tenants: Tenant[];
    tenantChanged: boolean;
}

export interface AppState {
    categories: Category[];
    tags: Tag[];
    groups: Group[];
    successMessage: string;
    errorMessage: string;
    triggerAppStateUpdate: boolean;
    loading: boolean;
    loadingCount: number;
}

export interface Document {
    id: string;
    title: string;
    description: string;
    fileName: string;
    originalFileName: string;
    fileType: FileType;
    fileStatus: string;
    status: DocumentStatus;
    groups: Group[];
    groupIds: string[];
    categories: Category[];
    tags: Tag[];
    date: Date | null;
    viewCount: number;
    createdByUserName: string;
    createdDate: Date;
    modifiedByUserName: string;
    modifiedDate: Date;
    lastViewUserName: string,
    lastViewDate: Date
}

export interface Group {
    id: string;
    name: string;
}

export interface Category {
    id: string;
    name: string;
}

export interface Tag {
    id: string;
    name: string;
}

export enum UserStatus {
    Inactive,
    Active,
    Pending,
    Rejected,
    None
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    emailVerified?: boolean;
    organization: string;
    isAdmin: boolean;
    status: UserStatus;
    createdDate: Date;
    lastLogin: Date;
    loginCount: number;
    groups: Group[];
    groupIds: string[];
}


export enum FileType {
    PDF = "PDF",
    WORD = "WORD",
    EXCEL = "EXCEL",
    POWERPOINT = "POWERPOINT",
    VIDEO = "VIDEO"
}

export enum DocumentStatus { Unpublished = "Unpublished", Published = "Published", Archived = "Archived" }

export interface ManageItem {
    id: string;
    name: string;
    count: number;
}

// INITIAL STATE
export const initialAppState: AppState = {
    categories: [],
    tags: [],
    groups: [],
    successMessage: "",
    errorMessage: "",
    triggerAppStateUpdate: false,
    loading: false,
    loadingCount: 0
}

export const initialTenantState: TenantState = {
    currentTenant: undefined,
    tenants: [],
    tenantChanged: false
}

const initialDispatchState = {
    dispatch: (a: Action) => { },
    tenantDispatch: (a: TenantAction) => { }
}

export enum ActionType {
    SetAppState = "SET_APP_STATE",
    ErrorNotification = "ERROR_NOTIFICATION",
    SuccessNotification = "SUCCESS_NOTIFICATION",
    ClearErrorNotification = "CLEAR_ERROR_NOTIFICATION",
    ClearSuccessNotification = "CLEAR_SUCCESS_NOTIFICATION",
    UpdateAppState = "UPDATE_APP_STATE",
    StartLoading = "START_LOADING",
    EndLoading = "END_LOADING"
}

export enum TenantActionType {
    SetTenants = "SET_TENANTS",
    SetCurrentTenant = "SET_CURRENT_TENANT"
}

export type TenantAction =
    | { type: TenantActionType.SetTenants, tenants: any[] }
    | { type: TenantActionType.SetCurrentTenant, tenantName: string }

export type Action =
    | { type: ActionType.SetAppState, categories: Category[], tags: Tag[], groups: Group[] }
    | { type: ActionType.ErrorNotification, message: string }
    | { type: ActionType.SuccessNotification, message: string }
    | { type: ActionType.ClearErrorNotification }
    | { type: ActionType.ClearSuccessNotification }
    | { type: ActionType.UpdateAppState }
    | { type: ActionType.StartLoading }
    | { type: ActionType.EndLoading };

export interface DispatchState {
    dispatch: Dispatch<Action>;
    tenantDispatch: Dispatch<TenantAction>;
}

// REDUCERS
export const tenantReducer = (state: TenantState, action: TenantAction): TenantState => {
    switch (action.type) {
        case TenantActionType.SetTenants:
            let currentTenant = action.tenants[0];

            const tenantNameFromLocal = localStorage.getItem("doc-manager-tenant");
            const localTenant = action.tenants.find(t => t.name === tenantNameFromLocal);

            if (localTenant) {
                currentTenant = localTenant;
            }
            else {
                console.log(currentTenant);
                localStorage.setItem("doc-manager-tenant", currentTenant.name);
            }

            return {
                ...state,
                tenants: action.tenants,
                currentTenant: currentTenant
            }
        case TenantActionType.SetCurrentTenant:
            return {
                ...state,
                currentTenant: state.tenants.find(t => t.name === action.tenantName),
                tenantChanged: true
            }
        default:
            return state;
    }
}

export const appReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case ActionType.SetAppState:
            return {
                ...state,
                categories: action.categories,
                tags: action.tags,
                groups: action.groups,
                triggerAppStateUpdate: false
            }
        case ActionType.SuccessNotification:
            return {
                ...state,
                successMessage: action.message
            }
        case ActionType.ErrorNotification:
            return {
                ...state,
                errorMessage: action.message
            }
        case ActionType.ClearErrorNotification:
            return {
                ...state,
                errorMessage: ""
            }
        case ActionType.ClearSuccessNotification:
            return {
                ...state,
                successMessage: ""
            }
        case ActionType.UpdateAppState:
            return {
                ...state,
                triggerAppStateUpdate: true
            }
        case ActionType.StartLoading: {
            return {
                ...state,
                loading: true,
                loadingCount: state.loadingCount + 1
            }
        }
        case ActionType.EndLoading: {
            let count = state.loadingCount - 1;
            let loading = state.loading;

            if (count <= 0) {
                loading = false;
                count = 0;
            }

            return {
                ...state,
                loading: loading,
                loadingCount: count
            }
        }
        default:
            console.error("Unknown action:", action);
            return state;
    }
}

// CONTEXTS
export const AppStateContext = React.createContext<AppState>(initialAppState);
export const TenantContext = React.createContext<TenantState>(initialTenantState);
export const DispatchContext = React.createContext<DispatchState>(initialDispatchState);
