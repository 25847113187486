import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { navigation, NavigationItem, TenantContext } from "./state";

export const Nav = (props: any) => {
    //State
    const tenantState = useContext(TenantContext);
    const history = useHistory();

    // Auth0
    const { logout, isAuthenticated } = useAuth0();

    // Navigation
    const setClass = (navItem: NavigationItem): string => {
        let parentSelected: boolean = history.location.pathname === navItem.path;
        let childSelected: boolean = navItem.children?.find(c => history.location.pathname === c.path) != null;
        return parentSelected || childSelected ? "selected link" : "link";
    }

    const buildLink = (navItem: NavigationItem): JSX.Element => {
        return (<a onClick={(e) => { e.preventDefault(); navigateToRoute(navItem); }}
            className={setClass(navItem) }
            href={navItem.path}>
            {navItem.displayName}
        </a>);
    }

    const navigateToRoute = (navItem: NavigationItem) => {
        history.push(navItem.path);
    }

    const navList: JSX.Element[] = navigation
        .filter((navItem: NavigationItem) => navItem.path.indexOf(":") === -1 && navItem.displayName)
        .map((navItem: NavigationItem, index: number) => {
            return (
                <li key={index}>
                    {buildLink(navItem)}
                    {navItem.children &&
                        <ul>
                            {navItem.children.map((childNavItem: NavigationItem, childIndex: number) => {
                                return (
                                    <li key={childIndex}>
                                        {buildLink(childNavItem)}
                                    </li>
                                )
                            })}
                        </ul>
                    }
                </li>
            );
        });

    return (
        <nav>
            <ul>
                {tenantState.currentTenant && navList}
                {isAuthenticated && <li><span className="link" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</span></li>}
            </ul>
        </nav>);
}